<!--
 * @Description: 分页组件
 * @Author: zhang zhen
 * @Date: 2023-02-14 14:24:00
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-18 22:09:18
 * @FilePath: /page-sass/src/components/plugins/ZPagination.vue
-->
<template>
  <div class="pageNations" :class="model == 'lessItems' && 'centerPage'">
    
    <a-pagination
      v-if="model == 'normal'"
      :current="current"
      :total="total"
      size="small"
      :pageSize="pageSize"
      show-size-changer
      :show-total="(total, range) => `共 ${total} 项数据`"
      show-quick-jumper
      :pageSizeOptions="pageSizeOptions"
      @change="handleChangePageInfo"
      @showSizeChange="handleSizeChange"
    />
    <a-pagination
      v-else
      :current="current"
      :total="total"
      :pageSize="pageSize"
      showLessItems
      @change="handleChangePageInfo"
      @showSizeChange="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  name: 'mainPagination',
  props: {
    model: {
      type: String,
      default: 'normal'
    },
    total: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      pageSizeOptions: ['20', '30', '40', '50'],
    }
  },
  methods: {
    /* 分页组件变化显示 */
    handleChangePageInfo(page, pageSize) {
      this.handlePageChange(page, pageSize)
    },
    /* 页码变化 */
    handleSizeChange(current, size) {
      this.handlePageChange(current, size)
    },
    handlePageChange(pageNo, pageSize) {
      this.$emit('handlePageChange', {
        pageSize,
        currentPage: pageNo || 1,
        pageNum: pageNo
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pageNations {
  margin-bottom: 15px;
  width: 100%;
  text-align: right;
  &.centerPage {
    text-align: center;
    margin-bottom: 0;
    margin-top: 8px;
  }
  ::v-deep.ant-pagination-disabled .ant-pagination-item-link {
    // background-color: #EFEFEF!important;
    .anticon {
      color: #BFBFBF;
    }
  }
  ::v-deep .ant-pagination-item {
    font-weight: 500;
    &:not(.ant-pagination-item-active) {
      border: none;
      a {
        font-weight: 500;
        color: #262626!important;
      }
    }
  }
  ::v-deep.ant-pagination-item-ellipsis {
    color: #262626;
  }
}


</style>
